<ngmy-table-filter
  *ngIf="!filterHidden"
  class="table-filter"
  [filterLabel]="filterLabel | translate"
  (changed)="onFilterChange($event)"
></ngmy-table-filter>
<div
  class="table-wrapper"
  [class.table-sticky]="isSticky(_columnsConfig)"
  [class.scrollable-x]="scrollableX"
  [class.scrollable-y]="scrollableY"
  [style.maxHeight.px]="_height"
>
  <!-- TODO: fixedLayout from angular 11 -->
  <table
    mat-table
    matSort
    style="table-layout: fixed"
    [matSortDisabled]="_sortDisabled"
    [matSortDirection]="sortDirection"
    [matSortActive]="sortActive"
    [multiTemplateDataRows]="!!multiRowConfig"
    [dataSource]="dataSource"
  >
    <!-- multi selection column -->
    <ngmy-multi-selection-column
      [sticky]="isSticky(_columnsConfig)"
      [disabled]="_selectionDisabled"
      [disableSelectionsAccessor]="disableSelectionsAccessor"
      [selectionsCollection]="selectionsCollection"
    ></ngmy-multi-selection-column>
    <!-- columns config -->
    <ng-container *ngFor="let columnConfig of _columnsConfig; trackBy: trackById">
      <ngmy-column-composer
        [hideHeader]="headerHidden"
        [hideFooter]="footerHidden"
        [sortDisabled]="_sortDisabled"
        [columnConfig]="columnConfig"
        [loading]="_loading"
      ></ngmy-column-composer>
    </ng-container>
    <!-- optional header -->
    <ng-container *ngIf="!headerHidden">
      <tr mat-header-row [ngClass]="rowClass" *matHeaderRowDef="columnsToDisplay"></tr>
    </ng-container>
    <!-- rows -->
    <tr
      mat-row
      [ngClass]="[rowClass, getHighlightClass(row, row.id)]"
      [class.selected]="row.id === dataSource.selected?.id"
      [class.loading-row]="_loading"
      *matRowDef="let row; columns: columnsToDisplay"
    ></tr>
    <!-- optional footer -->
    <tr
      mat-footer-row
      [ngClass]="rowClass"
      [class.hidden-footer]="_loading || footerHidden"
      *matFooterRowDef="columnsToDisplay"
    ></tr>
    <!-- multi row config -->
    <ng-container *ngIf="!!multiRowConfig">
      <ngmy-column-multi-row [config]="multiRowConfig"></ngmy-column-multi-row>
      <tr mat-row *matRowDef="let row; columns: ['multi-row']"></tr>
    </ng-container>
  </table>
</div>
<div *ngIf="!_loading && _data?.length === 0" class="table-no-data">{{ noDataLabel | translate }}</div>
<tpm-paginator *ngIf="!!_pagingConfig" [options]="_pagingConfig"></tpm-paginator>

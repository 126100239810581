import { CommonModule } from "@angular/common";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TranslateModule } from "@ngx-translate/core";
import { PipesModule } from "src/app/common/pipes/pipes.module";
import { TableFilterModule } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.module";
import { ColoredBadgeModule } from "../../colored-badge/colored-badge.module";
import { CheckboxFieldModule } from "../../form/checkbox-field/checkbox-field.module";
import { SkeletonLoaderModule } from "../../skeleton-loader/skeleton-loader.module";
import { TabNavBarModule } from "../../tab-nav-bar/tab-nav-bar.module";
import { DialogWrapperModule } from "../dialog-wrapper/dialog-wrapper.module";
import { GlossarySkeletonLoaderComponent } from "./components/glossary-loader/glossary-skeleton-loader.component";
import { RegulatedGlossaryDialogComponent } from "./components/regulated-glossary/regulated-glossary.component";
import { ServicesGlossaryDialogComponent } from "./components/services-glossary/services-glossary.component";

@NgModule({
  declarations: [GlossarySkeletonLoaderComponent, RegulatedGlossaryDialogComponent, ServicesGlossaryDialogComponent],
  imports: [
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    DialogWrapperModule,
    SkeletonLoaderModule,
    TabNavBarModule,
    CheckboxFieldModule,
    ColoredBadgeModule,
    MatLegacyInputModule,
    MatLegacyFormFieldModule,
    PipesModule,
    TableFilterModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class GlossaryDialogModule {}

import { IButtonToggleItem } from "src/app/common/components/button-toggle/button-toggle.component";
import { LaunchpadOverlay } from "./launchpad.interfaces";

export const LAUNCHPAD_SMALL_WIDTH = "(width <= 630px)";
export const LAUNCHPAD_MEDIUM_WIDTH = "(width <= 885px)";

export const BUTTONS_TOGGLE_LIST: Array<IButtonToggleItem<LaunchpadOverlay>> = [
  {
    value: "regulated",
    label: "Netzbetrieb"
  },
  {
    value: "marketplace",
    label: "Marketplace",
    preventedClick: true
  }
];

<div class="launchpad-wrapper" *ngrxLet="vm$ as vm">
  <div class="banners">
    <tpm-banners-carousel
      @inAnimation
      [isMedium]="isMarketplaceBannerMedium$ | async"
      [isMobile]="isTileSmall$ | async"
      [marketplaceList]="vm.marketplaceBanner.result"
      [newsList]="vm.news.carouselNews"
      [overlay]="vm.overlay"
      (newsBannerClicked)="onNewsCarouselBannerClick($event, vm.overlay)"
      (marketplaceBannerClicked)="onMarketplaceBannerClick($event, vm.overlay)"
    ></tpm-banners-carousel>
  </div>
  <tpm-card class="button-toggle-card">
    <div class="button-toggle-wrapper">
      <app-button-toggle
        [size]="'small'"
        [active]="vm.overlay"
        [items]="buttonsToggleList"
        (buttonChange)="changeOverlay($event)"
        (buttonPreventedClick)="changeOverlay($event)"
      ></app-button-toggle>
    </div>
  </tpm-card>
  <div class="tiles-grid-wrapper">
    <tpm-tiles-grid
      [isMobile]="isMobile$ | async"
      [list]="vm.list.result"
      [news]="vm.news.modulesNews"
      (newsCardClicked)="onNewsTileCardClick($event)"
      (tileClicked)="onTileClick($event)"
      (leadClicked)="onLeadClick($event)"
      (productManagementClicked)="onProductManagementClick($event)"
      (storyTellerClicked)="onStoryTellerClick($event, vm.overlay)"
      (newsClicked)="onNewsClicked(vm.overlay)"
      (subscriptionClicked)="onSubscriptionClicked()"
    ></tpm-tiles-grid>
  </div>
</div>

import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { AppConfig } from "../helpers/app.config";

@Injectable({ providedIn: "root" })
export class JsonsService {
  constructor(private readonly http: HttpClient) {}

  public getPlugins(): Observable<Array<Record<string, any>>> {
    return this.http.get<{ result: Array<Record<string, string>> }>(AppConfig.connection.json.plugins).pipe(
      map(({ result }) => result),
      catchError(() => of([]))
    );
  }

  public getRegulatedGlossaries(): Observable<any> {
    return this.http.get("/assets/mock/glossary.json").pipe(catchError(() => of({})));
  }
}

<div class="card-news-details-wrapper">
  <div class="card-news-details">
    <!-- news header -->
    <div class="card-news-details__header">
      <tpm-icon class="close-button" icon="gpi-filled-cross" (click)="onClose()"></tpm-icon>
    </div>
    <!-- news body -->
    <div *ngIf="!!data" class="card-news-details__body">
      <img class="full-size-media" *ngIf="!data.selected.video" [src]="data.selected.teaserImage" />
      <div class="full-size-media" *ngIf="data.selected.video">
        <video-player
          [source]="$any(data.selected.video.fields.file.url)"
          [title]="$any(data.selected.video.fields.title)"
        ></video-player>
      </div>
      <div class="card-news-details__body__textual-content">
        <div class="elements">
          <div class="date body-1">{{ data.selected.date | date : "dd.MM.yyyy" }}</div>
          <tpm-colored-badge
            *ngIf="data.selected.isNew"
            class="new-badge"
            text="NEU"
            [backgroundColor]="'--ene-color-emerald-A500'"
          ></tpm-colored-badge>
          <tpm-icon *ngIf="data.selected.checkCritical" class="critical-badge" icon="ec_warning"></tpm-icon>
        </div>
        <div class="headline" [ngClass]="(isMobileView$ | async) === false ? 'dashboard-body-2' : 'headline-4'">
          {{ data.selected.headline }}
        </div>
        <div class="teaser body-1">{{ data.selected.teaser }}</div>
        <div class="cms__infotext text-article body-1" [innerHTML]="data.selected.textArticle"></div>
        <div *ngIf="data.selected.actions" class="card-news-details__body__actions">
          <button
            *ngFor="let action of data.selected.actions; trackBy: trackByLabel"
            ene-filled-button
            rightIcon
            size="large"
            color="primary"
            icon="ec_pfeil-re"
            (click)="onActionClick($any(action))"
          >
            {{ action.label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

import { ChangeDetectionStrategy, Component, Inject, Optional } from "@angular/core";
import { EneLayoutService } from "@enersis/ui-helper";
import { select, Store } from "@ngrx/store";
import { BehaviorSubject, combineLatest, merge, Observable } from "rxjs";
import { map, scan } from "rxjs/operators";
import { SLOT } from "src/app/common/enums/slot";
import { ContentfulContentType } from "src/app/core/interfaces/contentful";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { DialogsService } from "src/app/core/services/dialogs.service";
import { OVERLAY_DATA } from "src/app/core/services/overlay.service";
import { fetchContentfulEntries } from "src/app/ngrx/actions/contentful.actions";
import { updateUsersSettingsUsersStorage } from "src/app/ngrx/actions/users-settings.actions";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { WebinarDialogComponent } from "../components/webinar-dialog/webinar-dialog.component";
import { WebinarsEntry } from "../webinars.interfaces";
import { WebinarsService } from "../webinars.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-webinars",
  templateUrl: "./webinars.component.html",
  styleUrls: ["./webinars.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WebinarsService]
})
export class WebinarsComponent {
  public readonly search$ = new BehaviorSubject<string>("");
  public readonly module$ = new BehaviorSubject<SLOT | undefined>(undefined);

  public readonly vm$ = merge(
    this.layoutService.isMobileScreen$.pipe(map((isMobileView) => ({ isMobileView }))),
    this.store
      .pipe(select(selectLaunchpadOverlay))
      .pipe(map((overlay) => ({ headline: `CONTENTFUL.WEBINARS.TITLES.${overlay}` }))),
    this.getWebinars()
  ).pipe(scan((vm, values) => ({ ...vm, ...values }), {}));

  constructor(
    @Optional()
    @Inject(OVERLAY_DATA)
    private readonly overlayData: { contentType: ContentfulContentType },
    private readonly dialogsService: DialogsService,
    private readonly webinarsService: WebinarsService,
    private readonly layoutService: EneLayoutService,
    private readonly store: Store<RootState>,
    private readonly analytics: AnalyticsService
  ) {
    if (this.overlayData) {
      this.store.dispatch(fetchContentfulEntries({ contentType: this.overlayData.contentType }));
    }
  }

  public readonly trackById = (_: number, entry: any): string => entry.id;

  public onSearchChange(search: string): void {
    this.search$.next(search);
  }

  public onModuleChange(module: SLOT): void {
    this.module$.next(module);
  }

  public onMarkAllRead(webinars: Array<WebinarsEntry>): void {
    const items = webinars.filter(({ isNew }) => isNew).map(({ id }) => ({ key: id, value: true }));

    if (items.length) {
      this.store.dispatch(updateUsersSettingsUsersStorage({ items }));
    }
  }

  public onCardClick(data: WebinarsEntry): void {
    if (data.isNew) {
      this.store.dispatch(updateUsersSettingsUsersStorage({ items: [{ key: data.id, value: true }] }));
    }

    switch (data.mediaType) {
      case "pdf": {
        window.open(data.media, "_blank");
        break;
      }
      case "video": {
        this.dialogsService.open(WebinarDialogComponent, {
          panelClass: "webinar-dialog",
          autoFocus: false,
          width: "530px",
          data
        });
        break;
      }
    }

    this.analytics.trackWebinarsClick(data.headline);
  }

  private getWebinars(): Observable<{ entries: Array<any> }> {
    return combineLatest([this.webinarsService.getWebinarsEntries(), this.search$, this.module$]).pipe(
      map(([_entries, search, module]) => ({
        entries: _entries
          // filter by search
          .filter(({ headline }) => headline?.toLowerCase().includes(search))
          // filter by module
          .filter((entry) => ("moduleName" in entry ? entry.moduleName?.includes(module ?? "") : true))
      }))
    );
  }
}

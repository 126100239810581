import { ChangeDetectionStrategy, Component, Inject, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject, combineLatest } from "rxjs";
import { map, startWith, switchMap } from "rxjs/operators";
import { TabNavBarItem } from "src/app/common/components/tab-nav-bar/tab-nav-bar.interfaces";
import { AnalyticsService } from "src/app/core/services/analytics/analytics.service";
import { TableFilterComponent } from "src/app/slots/three-year-plan/common/components/table/components/table-filter/table-filter.component";
import { RegulatedGlossaryDialogService } from "./regulated-glossary.service";

export enum GlossaryTags {
  NE = 0,
  SM = 1,
  EUN = 2,
  AUV = 3,
  "3JP" = 4
}

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: "tpm-regulated-glossary-dialog",
  templateUrl: "./regulated-glossary.component.html",
  styleUrls: ["./regulated-glossary.component.scss", "../../glossary.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RegulatedGlossaryDialogService]
})
export class RegulatedGlossaryDialogComponent {
  @ViewChild("filterRef") public readonly filterRef!: TableFilterComponent;

  public readonly tabNavBarItems: Array<TabNavBarItem> = [
    { name: "definitions", label: "GLOSSARY_DIALOG.TABS.definitions" },
    { name: "moduleKnowledge", label: "GLOSSARY_DIALOG.TABS.moduleKnowledge" }
  ];
  public readonly tabChange$ = new BehaviorSubject<string>(this.tabNavBarItems[0].name);
  public readonly list$ = this.dialogService.getGlossaries().pipe(
    switchMap((result) =>
      combineLatest([
        this.tabChange$.asObservable(),
        this.search$,
        this.dialogForm.valueChanges.pipe(startWith(this.dialogForm.getRawValue()))
      ]).pipe(map(([tab, search, { tags }]) => ({ tab, tags, search, result })))
    ),
    map(({ tab, tags, search, result }) => {
      // [true, true] -> "NE", "EUN" -> "NE◬EUN"
      const tagsString = tags.map((tag, index) => (tag ? GlossaryTags[index] : "")).join("◬");

      return (
        result[tab]
          // filter by tags
          .filter((item) => item.tags.some((tag) => tagsString.includes(tag)))
          // translate for searching
          .map((item) => ({
            ...item,
            title: this.translate.instant(item.title),
            content: this.translate.instant(item.content)
          }))
          // filter by search term
          .filter((item) => `${item.title}${item.content}`.toLowerCase().includes(search.toLowerCase()))
          // sort alphabetically
          .sort((a, b) => a.title.localeCompare(b.title))
      );
    })
  );

  public readonly dialogForm: FormGroup;
  public readonly search$ = new BehaviorSubject<string>("");

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA)
    private readonly data: { tab: string },
    private readonly dialogService: RegulatedGlossaryDialogService,
    private readonly analytics: AnalyticsService,
    private readonly fb: FormBuilder,
    private readonly translate: TranslateService
  ) {
    if (this.data.tab) {
      this.tabChange$.next(this.data.tab);
    }

    this.dialogForm = this.fb.group({
      tags: this.fb.array([true, true, true, true, true])
    });
  }

  get tags(): FormArray {
    return this.dialogForm.controls["tags"] as FormArray;
  }

  public onTabChange(tab: string): void {
    this.tabChange$.next(tab);
    this.filterRef?.reset();
    this.analytics.trackGlossaryTabClick(tab);
  }

  public onTabInit(tab: string): void {
    this.analytics.trackGlossaryTabClick(tab);
  }
}

import { ComponentType } from "@angular/cdk/portal";
import { Injectable, OnDestroy } from "@angular/core";
import { MatLegacyDialog, MatLegacyDialogConfig, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { EneLayoutService } from "@enersis/ui-helper";
import { Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { RegulatedGlossaryDialogComponent } from "src/app/common/components/dialogs/glossary/components/regulated-glossary/regulated-glossary.component";
import { ServicesGlossaryDialogComponent } from "src/app/common/components/dialogs/glossary/components/services-glossary/services-glossary.component";
import { CampusOneAdminViewComponent } from "src/app/components/dialogs/campus-one-admin-view/campus-one-admin-view.component";
import { OrderModuleDialogComponent } from "src/app/components/dialogs/order-module-dialog/container/order-module-dialog.component";
import { ProductManagementDialogComponent } from "src/app/components/dialogs/product-management-dialog/container/product-management-dialog.component";
import { RootState } from "src/app/ngrx/root-reducers";
import { selectLaunchpadOverlay } from "src/app/ngrx/selectors/overlay.selectors";
import { PolicyDialogComponent, PolicyTab } from "src/app/shared/feature/policy-dialog/policy-dialog.component";
import { OrderFormEntry } from "../interfaces/contentful";
import { takeLatestFrom } from "../utils/ngrx";
import { AuthService } from "./auth.service";

@Injectable({ providedIn: "root" })
export class DialogsService implements OnDestroy {
  private readonly destroy$ = new Subject<void>();

  constructor(
    private readonly layoutService: EneLayoutService,
    private readonly matDialog: MatLegacyDialog,
    private readonly store: Store<RootState>,
    private readonly authService: AuthService
  ) {}

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public open(component: ComponentType<unknown>, config: MatLegacyDialogConfig = {}): MatLegacyDialogRef<unknown, any> {
    const dialogRef = this.matDialog.open(component, config);

    this.layoutService.isMobileScreen$.pipe(takeUntil(this.destroy$)).subscribe((isMobileView) => {
      if (isMobileView) {
        dialogRef.updateSize("100vw", "100vh");
      } else {
        dialogRef.updateSize(config.width, config.height);
      }
    });

    return dialogRef;
  }

  public openPolicyDialog(tab?: PolicyTab) {
    const disableClose = !this.authService.hasPolicy();

    this.open(PolicyDialogComponent, {
      panelClass: "policy-dialog",
      width: "600px",
      autoFocus: false,
      disableClose,
      data: { tab, closable: !disableClose }
    });
  }

  public openGlossary(tab?: string): MatLegacyDialogRef<unknown, any> {
    const overlay = takeLatestFrom(this.store, selectLaunchpadOverlay);
    const component = overlay === "module" ? RegulatedGlossaryDialogComponent : ServicesGlossaryDialogComponent;

    return this.open(component, { panelClass: "glossary-dialog", width: "640px", data: { tab } });
  }

  public openCampusOneAdminViewDialog(): void {
    this.open(CampusOneAdminViewComponent, {
      panelClass: "campus-one-admin-view-dialog",
      width: "100vw",
      height: "100vh",
      autoFocus: false
    });
  }

  public openOrderModuleDialog(moduleId: string, orderForm: OrderFormEntry): MatLegacyDialogRef<unknown, any> {
    return this.open(OrderModuleDialogComponent, {
      data: { moduleId, orderForm },
      panelClass: "order-module-dialog",
      width: "1236px",
      autoFocus: false
    });
  }

  public openProductManagementDialog(): void {
    this.open(ProductManagementDialogComponent, {
      panelClass: "product-management-dialog",
      width: "100vw",
      height: "100vh",
      autoFocus: false
    });
  }
}

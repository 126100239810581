<div class="ca-main">
  <h2>{{ "USER_SETTINGS.VIEW" | translate }}</h2>
  <ngmy-table-filter class="filter" (changed)="nameFilterChanged($event)"></ngmy-table-filter>
  <mat-button-toggle-group class="small secondary full-width" [value]="selectedTab" (change)="setTab($event.value)">
    <mat-button-toggle [value]="'municipalities'">
      {{ "USER_SETTINGS.LIST_MUNICIPALITIES.TITLE" | translate }}
    </mat-button-toggle>
    <mat-button-toggle [value]="'users'">
      {{ "USER_SETTINGS.LIST_USERS.TITLE" | translate }}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <table-expandable-rows
    *ngIf="config"
    class="table-expandable-rows"
    [columnsData]="config.columns"
    [nestedColumns]="config.nestedColumns"
    [nestedTemplate]="config.component"
    [module]="module"
    [pagingConfig]="pagingConfig"
    [disableSort]="false"
    [sortActive]="'regionName'"
    [sortDirection]="'asc'"
  ></table-expandable-rows>
  <button ene-filled-button icon="ec_data-import" (click)="downloadXslx()">
    {{ "USER_SETTINGS.DOWNLOAD_BUTTON" | translate | uppercase }}
  </button>
</div>

<gp-modal-frame [icon]="icon" [hasCloseButton]="hasCloseButton" [size]="width" (close-overlay)="onClose($any($event))">
  <h3 *ngIf="!!header" slot="header">{{ header }}</h3>
  <div slot="sticky" class="sticy-container">
    <ng-content select="[sticky]"></ng-content>
  </div>
  <div class="content-container">
    <ng-content></ng-content>
  </div>
  <div slot="footer" class="footer-container">
    <ng-content select="[footer]"></ng-content>
    <div class="actions-container">
      <button
        *ngIf="submitButtonLabel"
        data-cy="submit-button"
        class="submit-button"
        ene-filled-button
        size="large"
        [disabled]="submitDisabled"
        (click)="onSubmit()"
      >
        {{ submitButtonLabel }}
      </button>
      <button
        *ngIf="closeButtonLabel"
        data-cy="close-button"
        class="close-button"
        ene-outline-button
        size="large"
        (click)="onClose()"
      >
        {{ closeButtonLabel }}
      </button>
    </div>
  </div>
</gp-modal-frame>

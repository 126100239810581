<app-dialog-wrapper titleIcon="ec_help_begriffe" [hasActions]="false" [title]="'GLOSSARY_DIALOG.TITLE' | translate">
  <div class="glossary-content" stickedContent>
    <tpm-tab-nav-bar
      [items]="tabNavBarItems"
      [active]="tabChange$ | async"
      (initialized)="onTabInit($event)"
      (tabChange)="onTabChange($event)"
    ></tpm-tab-nav-bar>
    <form name="serviceGlossaryForm" [formGroup]="dialogForm">
      <div class="glossary-content__tags-checkboxes__title">{{ "GLOSSARY_DIALOG.FILTERS_TITLE" | translate }}</div>
      <tpm-checkbox-field
        class="glossary-content__tags-checkboxes__item"
        formControlName="co2Balance"
        [label]="'LAUNCHPAD.PLUGINS.co2Balance.name' | translate"
      ></tpm-checkbox-field>
    </form>
  </div>
  <div class="glossary-content">
    <ng-container *ngIf="markdownText$ | async as markdownText; else loadingRef">
      <div class="html-translate" [innerHTML]="markdownText | trustHtml"></div>
    </ng-container>
    <ng-template #loadingRef>
      <tpm-glossary-skeleton-loader></tpm-glossary-skeleton-loader>
    </ng-template>
  </div>
</app-dialog-wrapper>

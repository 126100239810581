<app-dialog-wrapper titleIcon="ec_help_begriffe" [hasActions]="false" [title]="'GLOSSARY_DIALOG.TITLE' | translate">
  <div class="glossary-content" stickedContent>
    <tpm-tab-nav-bar
      [items]="tabNavBarItems"
      [active]="tabChange$ | async"
      (initialized)="onTabInit($event)"
      (tabChange)="onTabChange($event)"
    ></tpm-tab-nav-bar>
    <form name="regulatedGlossaryForm" [formGroup]="dialogForm">
      <div class="glossary-content__tags-checkboxes__title">{{ "GLOSSARY_DIALOG.FILTERS_TITLE" | translate }}</div>
      <div class="glossary-content__tags-checkboxes" formArrayName="tags">
        <tpm-checkbox-field
          *ngFor="let control of tags.controls; let index = index"
          class="glossary-content__tags-checkboxes__item"
          [formControlName]="index"
          [label]="'GLOSSARY_DIALOG.FILTERS.' + index | translate"
        ></tpm-checkbox-field>
      </div>
      <ngmy-table-filter #filterRef (changed)="search$.next($event)"></ngmy-table-filter>
    </form>
  </div>
  <div class="glossary-content">
    <ng-container *ngIf="list$ | async as list; else loadingRef">
      <div class="glossary-content__glossary-item" *ngFor="let item of list; let last = last">
        <div class="headline-4">
          <span class="title">{{ item.title }}</span>
          <tpm-colored-badge
            *ngFor="let tag of item.tags"
            class="tag"
            [text]="tag"
            [icon]="'ec_tag'"
            [iconColor]="'--ene-color-secondary-500'"
            [color]="'--ene-color-black'"
            [backgroundColor]="'--ene-color-grey-50'"
          ></tpm-colored-badge>
        </div>
        <div class="html-translate" [innerHTML]="item.content"></div>
        <hr *ngIf="!last" />
      </div>
    </ng-container>
    <ng-template #loadingRef>
      <tpm-glossary-skeleton-loader></tpm-glossary-skeleton-loader>
    </ng-template>
  </div>
</app-dialog-wrapper>

import { coerceBooleanProperty } from "@angular/cdk/coercion";
import { ChangeDetectorRef, Directive, EventEmitter, Input, Optional, Output, Self } from "@angular/core";
import { ControlValueAccessor, NgControl, Validators } from "@angular/forms";

const VALIDITY_STATE_MAP: { [error: string]: Partial<ValidityState> } = {
  required: { valueMissing: true, valid: false },
  pattern: { patternMismatch: true, valid: false },
  minlength: { tooShort: true, valid: false },
  maxlength: { tooLong: true, valid: false },
  email: { typeMismatch: true, valid: false }
};

@Directive()
export class FormFieldControl implements ControlValueAccessor {
  @Output() public readonly changed = new EventEmitter<string | number | boolean>();

  @Input() public label: string;
  @Input() public placeholder: string;
  @Input() public appearance: unknown;
  @Input()
  public set value(_value: string | number) {
    this._value = _value;
  }
  public _value: string | number = "";

  @Input()
  public set required(_required: boolean) {
    this._required = coerceBooleanProperty(_required);
  }
  public get required(): boolean {
    return this._required ?? this.ngControl?.control?.hasValidator(Validators.required) ?? false;
  }
  public _required: boolean | undefined;

  @Input()
  public set disabled(_disabled: boolean) {
    this._disabled = coerceBooleanProperty(_disabled);
  }
  public get disabled(): boolean {
    return this._disabled;
  }
  public _disabled: boolean = false;

  constructor(@Self() @Optional() protected readonly ngControl: NgControl, protected readonly cdr: ChangeDetectorRef) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  public _onChange: (_: any) => void = () => void 0;
  public _onTouched: (_?: any) => void = () => void 0;

  public validityTransform = (value: any, nativeValidity: ValidityState): Partial<ValidityState> => {
    if (this.ngControl?.control?.pristine) {
      return { valid: true };
    }
    if (this.ngControl?.control?.errors?.required) {
      return VALIDITY_STATE_MAP["required"];
    }
    if (this.ngControl?.control?.errors?.pattern) {
      return VALIDITY_STATE_MAP["pattern"];
    }
    if (this.ngControl?.control?.errors?.minlength) {
      return VALIDITY_STATE_MAP["minlength"];
    }
    if (this.ngControl?.control?.errors?.maxlength) {
      return VALIDITY_STATE_MAP["maxlength"];
    }
    if (this.ngControl?.control?.errors?.email) {
      return VALIDITY_STATE_MAP["email"];
    }
    return nativeValidity;
  };

  public writeValue(obj: any): void {
    this.value = obj;
  }

  public registerOnChange(fn: (_: any) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: (_: any) => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(disabled: boolean): void {
    this._disabled = disabled;
    this.cdr.detectChanges();
  }
}

<mat-button-toggle-group
  class="secondary"
  data-cy="appButtonToggle"
  [ngClass]="_size"
  [class.full-width]="_fullWidth"
  [vertical]="isMobileScreen$ | async"
  [value]="active"
  (change)="buttonChange.emit($event.value)"
>
  <mat-button-toggle
    *ngFor="let item of items; trackBy: trackByValue"
    [disableRipple]="true"
    [disabled]="item.disabled || item.preventedClick"
    [class.mat-button-toggle--prevented-click]="item.preventedClick"
    [value]="item.value"
    (click)="onClick(item)"
  >
    {{ item.label | translate }}
  </mat-button-toggle>
</mat-button-toggle-group>
